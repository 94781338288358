import Header from "../components/Header";
import TimeAndPlace from "../components/TimeAndPlace";

export default function LandingPage() {

    return (
        <div>
            <Header route=""/>
            <TimeAndPlace/>
        </div>
    )

};