import './bootstrap.css';
import './site.css';
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import LandingPage from "./js/pages/LandingPage";
import RsvpPage from "./js/pages/RsvpPage";

function App() {
    return (
        <Router>
            <Routes>
            <Route path="/" element={<LandingPage/>}/>
            <Route path="/rsvp" element={<RsvpPage/>} />
            </Routes>
        </Router>
    );
}

export default App;
