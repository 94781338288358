function routeLink(currentRoute, route, linkText) {
    const link = window.location.origin + "/" + route;
    if (currentRoute === route) {
        return (<a className="nav-link disabled link" href={link}>{linkText}</a>)
    } else {
        return (<a className="nav-link active link" href={link}>{linkText}</a>)
    }
}

function Header(props) {
    return (
        <div className='p-3 mx-auto text-center'>
            <h1 style={{fontSize: '75px'}}>
                Teresa & Jonathan
            </h1>
            <h2>May 21st, 2022 • Quincy, MA</h2>
            <hr style={{
                width: '60%',
                marginTop: "2em",
                marginLeft: "auto",
                marginRight: "auto",
                color: "#507CB0",
                opacity: '1'
            }}/>

            <ul className="nav justify-content-center">
                <li className="nav-item">
                    {routeLink(props.route, "", "Home")}
                </li>
                <li className="nav-item">
                    {routeLink(props.route, "rsvp", "RSVP")}
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="https://www.theknot.com/us/teresa-krause-and-jonathan-goodwin-jul-2022/registry" target="_blank">Registry</a>
                </li>
                {/*
                <li className="nav-item">
                    <a className="nav-link" href="https://krausegoodwin.com/schedule">Event Schedule</a>
                </li>
                */}
            </ul>
        </div>
    );
}

export default Header;

