import location from '../../img/location.png';

function mapSvg() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-map"
             viewBox="0 0 16 16">
            <path fill-rule="evenodd"
                  d="M15.817.113A.5.5 0 0 1 16 .5v14a.5.5 0 0 1-.402.49l-5 1a.502.502 0 0 1-.196 0L5.5 15.01l-4.902.98A.5.5 0 0 1 0 15.5v-14a.5.5 0 0 1 .402-.49l5-1a.5.5 0 0 1 .196 0L10.5.99l4.902-.98a.5.5 0 0 1 .415.103zM10 1.91l-4-.8v12.98l4 .8V1.91zm1 12.98 4-.8V1.11l-4 .8v12.98zm-6-.8V1.11l-4 .8v12.98l4-.8z"/>
        </svg>
    )
}

function TimeAndPlace() {
    const googleMapsHouse = 'https://www.google.com/maps/place/35+Crabtree+Rd,+Quincy,+MA+02171';
    return (
        <div className='justify-content-evenly text-center'>
            <p style={{textAlign:'left', fontFamily: 'PlayfairDisplay', fontSize: "larger", width:'50%', marginRight:'auto', marginLeft:'auto', marginBottom:0}}>
                We're getting married!
                We look forward to having you at our small backyard celebration on Saturday May 21, 2022 at 4pm.
            </p>
                <img
                    alt="A Map"
                    src={location}
                    style={{maxWidth: '50%', marginTop: '6em'}}
                />
        </div>
    );
};

export default TimeAndPlace;