import Header from "../components/Header";

export default function RsvpPage() {

    return (
        <div className='text-center'>
            <Header route="rsvp"/>
            <iframe
                src="https://docs.google.com/forms/d/e/1FAIpQLSfAe_BHMvosnnLjGIVjhwRuG8zhJvj4mQRnhyEZ2tWCuy8aTg/viewform?embedded=true"
                width="900"
                height="1100"
                frameBorder="0"
                marginHeight="0"
                marginWidth="0">
                Loading…
            </iframe>
        </div>
    )

};